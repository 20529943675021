<template>
<div class="activate">
  <div class="background">
    <div class="container">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter has-text-centered">
          <div class="title-login">
            <p><b>{{ $t('ACTIVATE_ACCOUNT.TITLE') }}</b></p>
          </div>
        </div>
      </div>
      <div class="columns" v-if="isSuccess && !$store.state.showSpinner">
        <div class="column is-half is-offset-one-quarter">
          <MessageBox type="success" :title="$t('ACTIVATE_ACCOUNT.MESSAGE_SUCCES')" subtitle=""/>
        </div>
      </div>
      <div class="columns" v-if="!isSuccess && !$store.state.showSpinner">
        <div class="column is-half is-offset-one-quarter">
          <MessageBox type="error" :title="$t('ACTIVATE_ACCOUNT.MESSAGE_ERROR')" :subtitle="$t('ACTIVATE_ACCOUNT.MESSAGE_ERROR2')"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import MessageBox from "@/components/MessageBox.vue";
export default {
  name: "activate",
  components: {
    MessageBox
  },
  data() {
    return {
      isSuccess: false,
      isActive: false
    }
  },
  beforeCreate() {
    this.$store.commit("setShowSpinner", true);
    const email = this.$route.params.email;
    const hash_activacion = this.$route.params.hash;
    this.$store.dispatch("activateAccount", { email, hash_activacion }).then(user => {
      this.isSuccess = true;
      this.$store.commit("setShowSpinner", false);
    }).catch(error => {
      this.isSuccess = false;
      this.$store.commit("setShowSpinner", false);
    });
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.background {
  background: url("../assets/img/Fondo-registro.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  width: 100%;
  height: calc(100% - 120px);
}


.title-login {
  color: white;
  font-size: 2rem;
  padding-top: 3rem;
}

.login-auth {
  background-color: white;
  border-radius: .1rem;
  padding: 3.5rem 0;
  margin-bottom: 2rem;
}

.column-button {
  padding-top: 2.5rem;
  margin-bottom: -.5rem;
}

.argument-caixa {
  color: $color-text;
  font-size: .8rem;
}

.media-content {
  color: $primary !important;
}

.quadre-info {
  padding: .5rem 1.5rem 1.5rem 1.5rem;
  border: 1px solid $color-text;
  font-size: .95rem;
  color: $color-text;
}

.quadre-info .paragraf {
  padding-top: 1rem;
  font-size: .95rem;
}

</style>
